@import "variables";

.dcol-tabs {
  a {
    color: #000000;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      color: #000000;
    }

    &:focus,
    &:active,
    &:hover {
      opacity: 1;
    }

    span {
      display: inline-block;
      margin-left: 1rem;
    }
  }
}

.dcol-tab {
  &__title-active {
    color: #000000;
  }
}
