@use "sass:math";

$baseFontSize: 14;
$baseFontFamily: "IBM Plex Sans";
$toolbarHeight: 80px;

$colorGray-1: #f5f5f5;
$colorGray-2: rgba(0, 0, 0, 0.15);
$colorGray-3: rgba(0, 0, 0, 0.1);
$colorGray-4: rgba(0, 0, 0, 0.2);
$colorGray-5: rgba(0, 0, 0, 0.5);
$colorGray-6: rgba(0, 0, 0, 0.07);
$colorAccent: #0093ff;
$colorPrimary: #0171bb;

// number of zeros defines precision of calculation px to rem
$precision: 10000;

// calculates rem from px, 1rem is defined in html and body font-size
@function toRem($x) {
  @return math.div(round(math.div($x, $baseFontSize) * $precision), $precision) +
    rem;
}

// input options for for-size mixin
$sizePhoneOnly: 'phone-only';
$sizeTabletPortraitUp: 'tablet-portrait-up';
$sizeTabletLandscapeUp: 'tablet-landscape-up';
$sizeDesktopUp: 'desktop-up';
$sizeBigDesktopUp: 'big-desktop-up';

// mixin to be used for responsive mobile first design
@mixin for-size($size) {
  @if $size == $sizePhoneOnly {
    @media (max-width: 599px) { @content; }
  } @else if $size == $sizeTabletPortraitUp {
    @media (min-width: 600px) { @content; }
  } @else if $size == $sizeTabletLandscapeUp {
    @media (min-width: 900px) { @content; }
  } @else if $size == $sizeDesktopUp {
    @media (min-width: 1200px) { @content; }
  } @else if $size == $sizeBigDesktopUp {
    @media (min-width: 1800px) { @content; }
  }
}
