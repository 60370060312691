@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "utilities";
@import "side-panel";
@import "add-panel";
@import "table";
@import "tabs";
@import "item-details";
@import "mat-customizations";
@import "ngx-toastr/toastr";

@layer base {
  h1 {
    @apply text-2xl mb-6 font-bold;
    font-family: $baseFontFamily, sans-serif;
  }

  h2 {
    @apply text-xl mb-4 font-bold;
    font-family: $baseFontFamily, sans-serif;
  }

  h3 {
    @apply text-lg mb-3 font-bold;
    font-family: $baseFontFamily, sans-serif;
  }

  p {
    @apply mb-3 leading-normal;
  }

}

html, body { height: 100%; font-size: $baseFontSize+px}
body {
  font-family: $baseFontFamily, sans-serif;
  background: $colorPrimary;
}

a, a:visited {
  text-decoration: none;
  color: $colorAccent;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

}

.container {
  height: calc(100vh - #{$toolbarHeight});
}
