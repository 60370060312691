@import "variables";

.item-details {
  &__header {
    position: relative;
  }

  &__type {
    font-size: 34px;
    width: 34px !important;
    height: 34px !important;
    position: absolute;
    top: 0;
  }

  &__back-btn {
    position: relative;
    top: -6px;
    left: -10px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 30rem 30rem 30rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    margin-bottom: 2rem;
  }

  &__card {

    mat-icon {
      position: relative;
      top: 6px;
    }
  }

  &__desc-icon {
    position: relative;
    padding-left: toRem(32);

    .mat-icon {
      position: absolute;
      left: 0;
      top: toRem(-3);
    }
  }
}
