.add-panel {
  padding: 3rem;

  &__form-single-column {
    grid-column: span 2;
    text-align: center;

    .mat-form-field {
      width: 100%;
    }
  }

  &__form-double-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  &__form-action-row {
    margin-top: 2rem;
  }

}
