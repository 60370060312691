@import "variables";

.side-panel {

  &__header {
      margin-left: 20px;
  }

  &__content {
    padding: 20px;
  }

  &__form-double-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  &__form-single-column {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  &__actions {
    margin-top: 1rem;
  }
}
