@import "variables";

td.dcol-table__action-column {
  background: transparent !important;
  padding: 0;
  color: rgba(0, 0, 0, 0.3);
}

@media all and (min-width: 1024px) {
  td.dcol-table__action-column {
    width: 8rem;
    padding-right: 1rem;
  }
}

.dcol-table {

  &__container {
    position: relative;
    overflow: auto;

    table {
      width: 100%;
    }

    table td {
      background: white;
    }
  }

  &__no-data-row {
    text-align: center;
    padding: 2rem 0;
  }

  &__row {
    &:focus td,
    &:hover td,
    &:active td {
      background: $colorGray-3 !important;
    }
  }

  &__row-clickable {
    &:focus td,
    &:hover td,
    &:active td {
      background: $colorGray-3 !important;
    }

    td:not(.dcol-table__action-column) {
      cursor: pointer;
    }
  }

  &__cell_multiline {
    display: inline-block;
    margin: toRem(5) 0;
  }

  &__cell-multiline-item {
    line-height: 1.5;
    display: block;
    margin: toRem(2) 0;
  }

  tr.dcol-table__detail-row {
    height: 0;

    td.mat-cell {
      background: $colorGray-6;
    }

  }

  &__row-expandable td.mat-cell {
    border-bottom-width: 0;
  }

  &__detail-expanded td.mat-cell {
    background: $colorGray-6;
  }

  &__detail-container {
    overflow: hidden;
  }

}

.dcol-icon-red:hover {
  color: #df0050;
}

.dcol-icon-orange:hover {
  color: darkorange;
}
