/* You can add global styles to this file, and also import other style files */
@import "libs/material/src/styles";
@import "libs/shared/src/styles/styles";
@import "libs/shared/src/styles/variables";

.section-header {
  &__topbar {
    margin-bottom: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__spacer {
    flex: 1;
  }
}
