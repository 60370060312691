@import "variables";

.mat-card .mat-card-title {
  margin-bottom: 1.5rem;
}

.mat-card p {
  line-height: 1.5;
}

.mat-card-header-text {
  height: auto;
  margin: 0 !important;
}

span.has-mat-icon,
a.has-mat-icon {
  position: relative;
  display: inline-block;
  padding-left: 1.5rem;

  .mat-icon {
    position: absolute;
    left: 0;
    scale: 1.2;
  }
}

p .mat-icon.mat-icon-inline,
button .mat-icon.mat-icon-inline,
.mat-flat-button .mat-icon.mat-icon-inline {
  scale: 1.2
}

.mat-flat-button .mat-icon.mat-icon-inline {
  position: relative;
  top: toRem(-1);
}

a.mat-flat-button:not(.mat-accent) {
  color: rgba(0, 0, 0, 0.87);

  &:focus,
  &:hover,
  &:active,
  &:visited {
    color: rgba(0, 0, 0, 0.87);
  }
}

a.mat-menu-item,
a.mat-flat-button,
a.mat-icon-button {

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }

}

.mat-form-field-subscript-wrapper {
  margin-top: 0.5em !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}
